<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card flat class="mb-4">
          <v-card-title class="headline">{{ pageTitle }}</v-card-title>
          <v-card-subtitle
            >오후 선정산 대상 가맹점의 매출을 확인하고 확정할 수
            있습니다.</v-card-subtitle
          >
        </v-card>
      </v-col>
    </v-row>

    <!-- 투자자 정보 카드 추가 -->
    <v-row>
      <v-col cols="12">
        <v-card class="mb-4">
          <v-card-text>
            <v-row align="center" class="mb-4">
              <v-col cols="6" class="d-flex align-center">
                <div class="text-h6 mr-4">총 투자가능 잔액:</div>
                <div class="text-h5 font-weight-bold">
                  {{ formatCurrency(totalInvestBalance) }}원
                </div>
              </v-col>
              <v-col cols="6" class="d-flex align-center">
                <div class="text-h6 mr-4">선정산 대상 매출 합:</div>
                <div class="text-h5 font-weight-bold">
                  {{ formatCurrency(totalSumAmount) }}원
                </div>
              </v-col>
            </v-row>

            <v-data-table
              :headers="investorHeaders"
              :items="investors"
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:item="{ item }">
                <tr :class="{ 'red--text': isOverBalance(item) }">
                  <!-- <td>{{ props && props.item && props.item.fundaUser ? props.item.fundaUser.userName : '' }}</td> -->
                  <td>
                    {{ item && item.fundaUser ? item.fundaUser.userName : '' }}
                  </td>
                  <td class="text-right">
                    {{ item ? item.investmentRate : 0 }}%
                  </td>
                  <td class="text-right">
                    {{
                      formatCurrency(
                        item && item.fundaUser
                          ? item.fundaUser.withdrawBalance
                          : 0
                      )
                    }}원
                  </td>
                  <td class="text-right">
                    {{ formatCurrency(getExpectedInvestment(item)) }}원
                  </td>
                </tr>
              </template>
              <template v-slot:body.append>
                <tr class="font-weight-bold">
                  <td>합계</td>
                  <td class="text-right">{{ totalInvestorRate }}%</td>
                  <td class="text-right">
                    {{ formatCurrency(totalInvestBalance) }}원
                  </td>
                  <td class="text-right">
                    {{ formatCurrency(totalSumAmount) }}원
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="subtitle-1">
            <v-row align="center">
              <v-col cols="6">
                <div class="d-flex align-center">
                  <span class="mr-4">선택된 총매출:</span>
                  <span class="primary--text font-weight-bold"
                    >{{ formatCurrency(checkedTotalSales) }}원</span
                  >
                </div>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn color="primary" @click="handleConfirmEarlypay">
                  선정산 확정하기
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <!-- 테이블 헤더 2단 구조로 수정 -->
            <v-data-table
              :headers="allHeaders"
              :items="items"
              :loading="loading"
              :options.sync="options"
              :server-items-length="totalItems"
              :footer-props="{
                'items-per-page-options': [15, 30, 50, 100, 500],
                'items-per-page-text': '페이지당 항목 수',
              }"
              class="elevation-1"
            >
              <template v-slot:header.earlypay_status>
                <div class="d-flex flex-column align-center">
                  <v-checkbox
                    :input-value="allSelected"
                    @change="handleConfirmAll"
                    @click.stop.prevent
                    dense
                    hide-details
                    class="mb-1"
                    label="전체 선택"
                  ></v-checkbox>
                  <v-checkbox
                    :input-value="allCompletedSelected"
                    @change="handleConfirmAllCompleted"
                    @click.stop.prevent
                    dense
                    hide-details
                    label="완료 항목 선택"
                  ></v-checkbox>
                </div>
              </template>
              <template v-slot:item="{ item, index }">
                <tr>
                  <td class="text-center">
                    {{ (options.page - 1) * options.itemsPerPage + index + 1 }}
                  </td>
                  <td>
                    <router-link
                      :to="{
                        name: 'myfinbiz/customer/detail',
                        params: { id: item.customer_id },
                      }"
                    >
                      {{ item.name }}
                    </router-link>
                  </td>
                  <td>{{ item.date }}</td>
                  <td class="text-right">
                    {{ formatCurrency(item.sum_amount) }}
                  </td>
                  <td
                    :class="[
                      getCellClass('nice', getSalesStatus(item, '나이스정보')),
                      'text-right',
                    ]"
                  >
                    <div class="flex flex-col gap-1">
                      <template
                        v-if="getSaleDetails(item, '나이스정보').length > 0"
                      >
                        <div
                          v-for="(detail, index) in getSaleDetails(
                            item,
                            '나이스정보'
                          )"
                          :key="index"
                          :class="[
                            'detail-cell',
                            getCellClass('nice', detail.scraping_status),
                            {
                              'edited-value':
                                detail.scraping_status === 'edited',
                              notuse: detail.scraping_status === 'notuse',
                            },
                          ]"
                        >
                          {{
                            detail.scraping_status === 'notuse'
                              ? ''
                              : formatCurrency(detail.amount)
                          }}
                        </div>
                      </template>
                    </div>
                  </td>
                  <td
                    :class="[
                      getCellClass('nice', getSalesStatus(item, 'koces')),
                      'text-right',
                    ]"
                  >
                    <div class="flex flex-col gap-1">
                      <template v-if="getSaleDetails(item, 'koces').length > 0">
                        <div
                          v-for="(detail, index) in getSaleDetails(
                            item,
                            'koces'
                          )"
                          :key="index"
                          :class="[
                            'detail-cell',
                            getCellClass('nice', detail.scraping_status),
                            {
                              'edited-value':
                                detail.scraping_status === 'edited',
                              notuse: detail.scraping_status === 'notuse',
                            },
                          ]"
                        >
                          {{
                            detail.scraping_status === 'notuse'
                              ? ''
                              : formatCurrency(detail.amount)
                          }}
                        </div>
                      </template>
                    </div>
                  </td>

                  <!-- 카드사 매출 -->
                  <td
                    v-for="company in cardCompanies"
                    :key="`card-${company.code}`"
                    :class="[
                      getCellClass(
                        'credit',
                        getSalesStatus(item, company.code)
                      ),
                      'text-right',
                    ]"
                  >
                    <div class="flex flex-col gap-1">
                      <template
                        v-if="getSaleDetails(item, company.code).length > 0"
                      >
                        <div
                          v-for="(detail, index) in getSaleDetails(
                            item,
                            company.code
                          )"
                          :key="index"
                          :class="[
                            'detail-cell',
                            getCellClass('credit', detail.scraping_status),
                            {
                              'edited-value':
                                detail.scraping_status === 'edited',
                              notuse: detail.scraping_status === 'notuse',
                            },
                          ]"
                        >
                          {{
                            detail.scraping_status === 'notuse'
                              ? ''
                              : formatCurrency(detail.amount)
                          }}
                        </div>
                      </template>
                    </div>
                  </td>

                  <!-- 배달앱 매출 -->
                  <td
                    v-for="company in deliveryCompanies"
                    :key="`delivery-${company.code}`"
                    :class="[
                      getCellClass(
                        'delivery',
                        getSalesStatus(item, company.code)
                      ),
                      'text-right',
                    ]"
                  >
                    <div class="flex flex-col gap-1">
                      <template
                        v-if="getSaleDetails(item, company.code).length > 0"
                      >
                        <div
                          v-for="(detail, index) in getSaleDetails(
                            item,
                            company.code
                          )"
                          :key="index"
                          :class="[
                            'detail-cell',
                            getCellClass('delivery', detail.scraping_status),
                            {
                              'edited-value':
                                detail.scraping_status === 'edited',
                              notuse: detail.scraping_status === 'notuse',
                            },
                          ]"
                        >
                          {{
                            detail.scraping_status === 'notuse'
                              ? ''
                              : formatCurrency(detail.amount)
                          }}
                        </div>
                      </template>
                    </div>
                  </td>

                  <td class="text-center">
                    <v-checkbox
                      v-model="item.earlypay_status"
                      :true-value="'confirm'"
                      :false-value="'pending'"
                      @change="handleSelectRow(item)"
                      dense
                      hide-details
                    ></v-checkbox>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'AfternoonConfirm',
  components: {},
  data() {
    return {
      loading: false,
      items: [],
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 500,
        sortBy: ['name'],
        sortDesc: [false],
      },
      cardCompanies: [
        { label: '신한카드', code: '03' },
        { label: 'KB카드', code: '01' },
        { label: '비씨카드', code: '04' },
        { label: '롯데카드', code: '11' },
        { label: '현대카드', code: '12' },
        { label: '삼성카드', code: '13' },
        { label: '농협NH카드', code: '19' },
        { label: '하나카드', code: '21' },
        { label: '우리카드', code: '23' },
      ],
      deliveryCompanies: [
        { label: '배민', code: 'baemin' },
        { label: '요기요', code: 'yogiyo' },
        { label: '쿠팡잇츠', code: 'coupang' },
        { label: '휘슬페이', code: 'whistle' },
      ],
      headers: [
        { text: '가맹점', value: 'name', width: '96px' },
        { text: '입금일', value: 'date', width: '64px' },
        { text: '총매출', value: 'sum_amount', width: '72px', align: 'right' },
        { text: '나이스', value: 'nice', width: '72px', align: 'right' },
        { text: 'koces', value: 'koces', width: '72px', align: 'right' },
        // 카드사 헤더는 동적으로 추가
        // 배달앱 헤더는 동적으로 추가
        {
          text: '확정',
          value: 'earlypay_status',
          width: '40px',
          align: 'center',
        },
      ],
      investors: [],
      totalInvestBalance: 0,
      totalSumAmount: 0,
      allSelected: false,
      allCompletedSelected: false,
      investorHeaders: [
        { text: '투자자명', value: 'fundaUser.userName', align: 'start' },
        { text: '투자 비율', value: 'investmentRate', align: 'end' },
        {
          text: '투자가능 잔액',
          value: 'fundaUser.withdrawBalance',
          align: 'end',
        },
        { text: '예상 투자금액', value: 'expectedInvestment', align: 'end' },
      ],
    }
  },
  computed: {
    ...mapGetters('myfinbiz/auth', [
      'getToken',
      'isAuthenticated',
      'getUser',
      'getOrganization',
    ]),
    organizationId() {
      return this.getUser && this.getUser.organizationId
    },
    pageTitle() {
      return `${this.getOrganization?.name || ''} 오후 선정산 확정`
    },
    allHeaders() {
      const baseHeaders = [
        {
          text: 'No.',
          value: 'index',
          width: '40px',
          align: 'center',
        },
        {
          text: '가맹점',
          value: 'name',
          width: '96px',
        },
        {
          text: '입금일',
          value: 'date',
          width: '64px',
        },
        {
          text: '총매출',
          value: 'sum_amount',
          width: '72px',
          align: 'right',
        },
        {
          text: '나이스',
          value: 'nice',
          width: '72px',
          align: 'right',
        },
        {
          text: 'koces',
          value: 'koces',
          width: '72px',
          align: 'right',
        },
      ]

      // 카드사 헤더 그룹
      const cardHeaders = this.cardCompanies.map((company) => ({
        text: company.label,
        value: `card_${company.code}`,
        width: '72px',
        align: 'right',
        groupName: '여신금융협회(밴사매출 중복제외)',
      }))

      // 배달앱 헤더
      const deliveryHeaders = this.deliveryCompanies.map((company) => ({
        text: company.label,
        value: company.code,
        width: '72px',
        align: 'right',
      }))

      // 확정 체크박스 헤더
      const confirmHeader = {
        text: '확정',
        value: 'earlypay_status',
        width: '120px',
        align: 'center',
      }

      return [...baseHeaders, ...cardHeaders, ...deliveryHeaders, confirmHeader]
    },
    checkedTotalSales() {
      return this.items
        .filter((item) => item.earlypay_status === 'confirm')
        .reduce((sum, item) => sum + (item.sum_amount || 0), 0)
    },
    totalInvestorRate() {
      const total = this.investors.reduce(
        (sum, investor) => sum + investor.investmentRate,
        0
      )
      // 소수점 2자리까지 반올림
      return Math.round(total * 100) / 100
    },
    investorsWithExpectedAmount() {
      return this.investors.map((investor) => ({
        ...investor,
        expectedInvestment: this.getExpectedInvestment(investor),
      }))
    },
    hasOverInvestment() {
      return this.investors.some((investor) => this.isOverBalance(investor))
    },
    salesByType() {
      return {
        nice: this.items.reduce((sum, item) => {
          const niceSale =
            item.sales && item.sales.find((s) => s.provider === '나이스정보')
          return sum + ((niceSale && niceSale.amount) || 0)
        }, 0),
        koces: this.items.reduce((sum, item) => {
          const kocesSale =
            item.sales && item.sales.find((s) => s.provider === 'koces')
          return sum + ((kocesSale && kocesSale.amount) || 0)
        }, 0),
      }
    },
    cardSalesTotal() {
      return this.cardCompanies.reduce((acc, company) => {
        acc[company.code] = this.items.reduce((sum, item) => {
          const sale =
            item.sales &&
            item.sales.find((s) => s.payment_company === company.code)
          return sum + ((sale && sale.amount) || 0)
        }, 0)
        return acc
      }, {})
    },
    deliverySalesTotal() {
      return this.deliveryCompanies.reduce((acc, company) => {
        acc[company.code] = this.items.reduce((sum, item) => {
          const sale =
            item.sales && item.sales.find((s) => s.provider === company.code)
          return sum + ((sale && sale.amount) || 0)
        }, 0)
        return acc
      }, {})
    },
    processedTableData() {
      const processedData = this.items.map((item) => ({
        ...item,
        formattedSales: this.formatSalesData(item.sales),
      }))

      console.log('processedData ', processedData)

      return processedData
    },
    allSaleTypes() {
      const cardCodes = this.cardCompanies.map((c) => c.code)
      const deliveryCodes = this.deliveryCompanies.map((d) => d.code)
      return ['나이스정보', 'koces', ...cardCodes, ...deliveryCodes]
    },
  },
  watch: {
    options: {
      immediate: true,
      handler() {
        if (this.isAuthenticated) {
          this.loadItems()
        }
      },
      deep: true,
    },
  },
  created() {
    if (!this.isAuthenticated) {
      this.$router.push({ name: 'myfinbiz/login' })
      return
    }
    this.headers = this.allHeaders
    this.loadPaginatedInvestors()
  },
  methods: {
    formatCurrency(value) {
      if (value === undefined || value === null) return ''
      return new Intl.NumberFormat('ko-KR').format(value)
    },
    formatSalesAmount(sales, code) {
      if (!sales) return ''
      const sale = sales.find(
        (s) => s.provider === code || s.payment_company === code
      )
      if (!sale) return ''

      // amount가 0이더라도 scraping_status가 있으면 0을 표시
      if (sale.scraping_status && sale.scraping_status !== 'notuse') {
        return this.formatCurrency(sale.amount || 0)
      }

      // amount가 없거나 0이고 scraping_status가 없거나 notuse면 빈 문자열 반환
      if (!sale.amount) return ''

      return this.formatCurrency(sale.amount)
    },
    async loadItems() {
      this.loading = true
      try {
        if (!this.organizationId) {
          console.warn('organizationId is not available')
          return
        }

        const params = {
          date: dayjs().format('YYYY-MM-DD'),
          page: this.options.page,
          limit: this.options.itemsPerPage,
          status: 'pending',
          titleCode: 'pm_etc',
          organizationId: this.organizationId,
        }

        console.log('API Request params:', params)

        const response = await this.$myfinbizApi.earlyPayment.getList(params)
        console.log('Full API Response:', response)

        // API 응답 구조에 맞게 데이터 추출
        const responseData = response.data.data.data

        if (responseData && Array.isArray(responseData.data)) {
          this.items = responseData.data.map((item) => ({
            ...item,
            formattedSales: this.formatSalesData(item.sales),
          }))
          this.totalItems = responseData.total
          this.totalSumAmount = responseData.total_sum_amount
          console.log('Processed items:', this.items)
        } else {
          console.warn('Unexpected response structure:', response.data)
          this.items = []
          this.totalItems = 0
        }
      } catch (error) {
        console.error('Failed to load items:', error)
        console.error('Error details:', error.response?.data || error.message)
        // alert로 변경
        alert('데이터를 불러오는데 실패했습니다.')
      } finally {
        this.loading = false
      }
    },
    handleSelectRow(item) {
      console.log('Selected item:', {
        id: item.id,
        status: item.earlypay_status,
        isConfirmed: item.earlypay_status === 'confirm',
      })

      // 전체 선택 상태 업데이트
      this.allSelected = this.items.every(
        (item) => item.earlypay_status === 'confirm'
      )

      // 완료 항목 전체 선택 상태도 업데이트
      this.allCompletedSelected = this.items
        .filter((item) =>
          Object.values(item.formattedSales).every((sale) =>
            sale.details.every(
              (detail) =>
                detail.scraping_status === 'completed' ||
                detail.scraping_status === 'notuse'
            )
          )
        )
        .every((item) => item.earlypay_status === 'confirm')

      console.log('items = ', this.items)
    },
    handleConfirmAll(checked) {
      console.log('handleConfirmAll called with:', checked)
      // allSelected 값을 업데이트
      this.allSelected = checked

      // 모든 아이템의 상태를 변경
      const newStatus = checked ? 'confirm' : 'pending'
      this.items = this.items.map((item) => ({
        ...item,
        earlypay_status: newStatus,
      }))

      console.log('Updated items:', this.items)
    },
    handleConfirmAllCompleted(checked) {
      console.log('handleConfirmAllCompleted called with:', checked)
      this.allCompletedSelected = checked

      // 모든 sales의 details가 completed 또는 notuse인 item들만 선택
      this.items = this.items.map((item) => {
        const isAllCompleted = Object.values(item.formattedSales).every(
          (sale) =>
            sale.details.every(
              (detail) =>
                detail.scraping_status === 'completed' ||
                detail.scraping_status === 'notuse'
            )
        )

        return {
          ...item,
          earlypay_status: isAllCompleted ? 'confirm' : item.earlypay_status,
        }
      })

      // 전체 선택 상태도 업데이트
      this.allSelected = this.items.every(
        (item) => item.earlypay_status === 'confirm'
      )

      console.log('Updated items:', this.items)
    },
    getCellClass(storeType, status) {
      // 기본 스타일 클래스
      const baseClass = ['text-right']

      // 상태가 없으면 미사용 처리
      if (!status) return [...baseClass, 'notuse']

      // 상태별 클래스 매핑
      const statusClass =
        {
          notuse: 'notuse',
          pending: 'pending',
          error: 'error',
          edited: 'edited',
          completed: 'completed',
        }[status] || 'notuse'

      return [...baseClass, statusClass]
    },
    getStoreType(code) {
      if (code === '나이스정보') return 'nice'
      if (this.cardCompanies.some((c) => c.code === code)) return 'credit'
      return 'delivery'
    },
    async loadPaginatedInvestors() {
      try {
        const response = await this.$myfinbizApi.earlyPayment.getInvestorList({
          page: 1,
          limit: 100,
        })

        console.log('loadPaginatedInvestors', response)
        this.investors = response.data.data.data.data.map((item) => ({
          ...item,
          // 투자 비율을 소수점 2자리까지 반올림
          investmentRate: Math.round(item.investmentRate * 10000) / 100,
        }))
        console.log('this.investors', this.investors)

        this.totalInvestBalance = this.investors.reduce(
          (acc, cur) => acc + cur.fundaUser.withdrawBalance,
          0
        )
      } catch (error) {
        console.error('Failed to load investors:', error)
      }
    },
    formatSalesData(sales) {
      const allPaymentTypes = [
        '나이스정보',
        'koces',
        ...this.cardCompanies.map((c) => c.code),
        ...this.deliveryCompanies.map((d) => d.code),
      ]

      // 기본 데이터 구조 생성
      const formattedData = allPaymentTypes.reduce((acc, type) => {
        acc[type] = {
          code: type,
          details: [],
        }
        return acc
      }, {})

      // 실제 sales 데이터 처리
      sales.forEach((sale) => {
        const key = sale.payment_company || sale.provider
        if (!key || !formattedData[key]) {
          console.log('no key no formattedData')
          return
        }

        formattedData[key].details.push({
          amount: sale.amount || 0,
          scraping_status: sale.scraping_status || 'notuse',
          scraping_status_id: sale.scraping_status_id || null,
        })
      })

      // 빈 details 배열에 대한 처리
      allPaymentTypes.forEach((type) => {
        if (formattedData[type].details.length === 0) {
          formattedData[type].details.push({
            amount: 0,
            scraping_status: 'notuse',
            scraping_status_id: null,
          })
        }
      })

      return formattedData
    },
    handleCheckboxChange(checked, item) {
      // Boolean 값을 문자열로 변환하여 저장
      item.earlypay_status = checked ? 'confirm' : 'pending'
      this.handleSelectRow(item)

      console.log('handleCheckboxChange', this.items)
    },
    async handleConfirmEarlypay() {
      const confirmedStores = this.items
        .filter((item) => item.earlypay_status === 'confirm')
        .map((item) => {
          // 모든 payment type의 scraping_status_ids 수집
          const allStatusIds = Object.values(item.formattedSales)
            .flatMap((sale) =>
              sale.details
                .filter(
                  (detail) =>
                    detail.scraping_status === 'completed' ||
                    detail.scraping_status === 'edited'
                )
                .map((detail) => detail.scraping_status_id)
            )
            .filter((id) => id != null)

          return {
            id: item.id.toString(),
            scraping_status_ids: allStatusIds,
          }
        })

      console.log('confirmedStores:', confirmedStores)

      if (confirmedStores.length === 0) {
        alert('선택된 가맹점이 없습니다.')
        return
      }

      try {
        await this.$myfinbizApi.earlyPayment.confirm({
          stores: confirmedStores,
        })

        alert('선정산 확정이 완료되었습니다.')
        await this.loadItems()
      } catch (error) {
        console.error('선정산 확정 중 오류:', error)
        alert('선정산 확정 중 오류가 발생했습니다.')
      }
    },
    isOverBalance(investor) {
      if (!investor || !investor.fundaUser) return false
      const expectedInvestment = this.getExpectedInvestment(investor)
      return expectedInvestment > investor.fundaUser.withdrawBalance
    },
    getExpectedInvestment(investor) {
      if (!investor) return 0
      return Math.floor(this.totalSumAmount * (investor.investmentRate / 100))
    },
    // 매출 금액 가져오기
    getSalesAmount(item, code) {
      if (!item || !item.formattedSales) return 0
      const sale = item.formattedSales[code]
      if (!sale || !sale.details.length) return 0

      return sale.details.reduce((sum, detail) => {
        // scraping_status가 notuse가 아닌 경우에만 합산
        if (detail.scraping_status !== 'notuse') {
          return sum + (detail.amount || 0)
        }
        return sum
      }, 0)
    },
    // 매출 상태 가져오기
    getSalesStatus(item, code) {
      if (!item || !item.formattedSales) return 'notuse'

      const sale = item.formattedSales[code]
      if (!sale || !sale.details.length) return 'notuse'

      // 상태 우선순위에 따라 결정
      if (sale.details.some((d) => d.scraping_status === 'error')) {
        return 'error'
      }
      if (sale.details.some((d) => d.scraping_status === 'pending')) {
        return 'pending'
      }
      if (sale.details.some((d) => d.scraping_status === 'edited')) {
        return 'edited'
      }
      if (sale.details.every((d) => d.scraping_status === 'completed')) {
        return 'completed'
      }
      return 'notuse'
    },
    // 매출 상세 정보 가져오기
    getSaleDetails(item, code) {
      // 1. item이나 formattedSales가 없는 경우
      if (!item || !item.formattedSales) {
        return [
          {
            amount: 0,
            scraping_status: 'notuse',
          },
        ]
      }

      // 2. 해당 코드의 매출 정보가 없는 경우
      const sale = item.formattedSales[code]
      if (!sale || !sale.details.length) {
        return [
          {
            amount: 0,
            scraping_status: 'notuse',
          },
        ]
      }

      // 3. 정상적인 매출 상세 정보 반환
      return sale.details
    },
  },
}
</script>

<style lang="scss" scoped>
.v-data-table {
  width: 100%;
  ::v-deep {
    td {
      height: auto !important;
      min-height: 28px;
      white-space: normal;
      font-size: 0.75rem;
      padding: 0 2px !important;

      // 다중 값 표시를 위한 스타일
      .flex-col {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }

      // 수정된 값 스타일
      .edited-value {
        font-weight: 600;
        font-style: italic;
      }

      // detail-cell 스타일
      .detail-cell {
        padding: 2px 4px;
        border-radius: 2px;
        margin: 1px 0;

        &.pending,
        &.error {
          background-color: rgba(236, 93, 93, 0.9) !important;
          color: white;
        }

        &.notuse {
          background-color: rgba(235, 235, 235, 0.9) !important;
          color: #000;
        }

        &.edited {
          background-color: rgba(254, 240, 138, 0.9) !important;
          color: #000;
        }

        &.completed {
          background-color: rgba(255, 255, 255, 0.9) !important;
          color: #000;
        }

        &.edited-value {
          font-weight: 600;
          font-style: italic;
        }
      }

      // 체크박스 셀 스타일 수정
      &:last-child {
        padding: 0 !important;
        height: 100% !important;
        text-align: center;
        min-width: 120px !important;
        width: 120px !important;

        .v-input--selection-controls {
          margin: 0;
          padding: 0;
          height: 100%;
          min-height: inherit;

          .v-input__slot {
            margin: 0;
            padding: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    th {
      font-size: 0.75rem !important;
      padding: 0 2px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center !important;
    }

    .v-data-table__wrapper {
      overflow-x: auto;
    }

    .v-input--selection-controls {
      margin: 0;
      padding: 0;
      transform: scale(0.8);
    }

    // 헤더 텍스트 줄임
    .text-start {
      max-width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    // 상태별 스타일
    .pending,
    .error {
      background-color: #ec5d5d !important;
      color: white;
    }

    .notuse {
      background-color: #ebebeb !important;
      color: #000;
    }

    .edited {
      background-color: #fef08a !important;
      color: #000;
    }

    .completed {
      color: #000;
    }

    // 테이블 레이아웃 수정
    table {
      table-layout: fixed;
      width: 100%;
    }

    thead th {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: white;
    }

    td,
    th {
      width: auto;
      &[width] {
        width: attr(width);
      }
    }

    // 체크박스 헤더 스타일 수정
    th:last-child {
      min-width: 120px !important;
      width: 120px !important;
      white-space: normal !important;

      .d-flex.flex-column {
        min-width: 100px;
        white-space: normal;

        .v-input--selection-controls {
          margin-bottom: 4px;

          .v-label {
            white-space: normal;
            line-height: 1.2;
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}

.headline {
  font-size: 1.5rem !important;
  font-weight: 500;
}

.subtitle-1 {
  font-size: 1.1rem !important;
}

// 스크래핑 상태에 따른 셀 스타일
::v-deep {
  .text-right {
    text-align: right;
  }

  td {
    padding: 1px 2px;
    border-radius: 1px;
    font-size: 0.75rem;
  }

  .pending,
  .error {
    background-color: #ec5d5d !important;
    color: white;
  }

  .notuse {
    background-color: #ebebeb !important;
    color: #000;
  }

  .edited {
    background-color: #fef08a !important;
    color: #000;
  }

  .completed {
    color: #000;
  }
}

// 투자자 테이블 스타일 추가
.v-data-table ::v-deep {
  .red--text {
    color: #ef4444 !important;
    font-weight: 600;
  }
}

// 2단 헤더 스타일
.v-data-table ::v-deep thead {
  th {
    text-align: center;
    vertical-align: middle;

    &[rowspan='2'] {
      vertical-align: middle;
    }
  }
}

.v-data-table ::v-deep {
  .v-data-table-header {
    th {
      font-size: 0.75rem !important;
      padding: 0 2px !important;
      white-space: nowrap;
      height: auto !important;
    }
  }

  .v-data-table__group-header {
    background-color: #f5f5f5;
    font-weight: bold;
  }
}
</style>
