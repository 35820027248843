<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h2 class="mb-4">{{ pageTitle }}</h2>
        <v-card>
          <v-tabs v-model="activeTab" background-color="primary" dark>
            <v-tab>오전 확정</v-tab>
            <v-tab>오후 확정</v-tab>
            <v-tab>확정후 대기중</v-tab>
            <v-tab>입금 기록</v-tab>
          </v-tabs>

          <v-tabs-items v-model="activeTab">
            <v-tab-item>
              <MorningConfirm />
            </v-tab-item>
            <v-tab-item>
              <AfternoonConfirm />
            </v-tab-item>
            <v-tab-item>
              <ConfirmPending />
            </v-tab-item>
            <v-tab-item>
              <PaymentHistory />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import MorningConfirm from './early-payments/MorningConfirm.vue'
import AfternoonConfirm from './early-payments/AfternoonConfirm.vue'
import ConfirmPending from './early-payments/ConfirmPending.vue'
import PaymentHistory from './early-payments/PaymentHistory.vue'

export default {
  name: 'MyfinbizEarlyPayments',
  components: {
    MorningConfirm,
    AfternoonConfirm,
    ConfirmPending,
    PaymentHistory
  },
  data() {
    return {
      activeTab: 0
    }
  },
  computed: {
    ...mapGetters('myfinbiz/auth', ['getOrganization']),
    pageTitle() {
      return `${this.getOrganization?.name || ''} 선정산 관리`
    }
  }
}
</script>

<style lang="scss" scoped>
.v-tabs-items {
  background-color: #f5f5f5;
}
</style>
