<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h2>입금 기록</h2>

        <!-- 검색 필터 -->
        <v-card class="mb-4">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="3">
                <v-select
                  v-model="filters.loanStatus"
                  :items="loanStatuses"
                  label="상태"
                  clearable
                  dense
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-row>
                  <v-col>
                    <v-menu
                      ref="startMenu"
                      v-model="startMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="filters.startDate"
                          label="시작일"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                        />
                      </template>
                      <v-date-picker
                        v-model="filters.startDate"
                        no-title
                        @input="startMenu = false"
                      />
                    </v-menu>
                  </v-col>
                  <v-col>
                    <v-menu
                      ref="endMenu"
                      v-model="endMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="filters.endDate"
                          label="종료일"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                        />
                      </template>
                      <v-date-picker
                        v-model="filters.endDate"
                        no-title
                        @input="endMenu = false"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="3">
                <v-row>
                  <v-col>
                    <v-btn small @click="setDate('today')">당일</v-btn>
                  </v-col>
                  <v-col>
                    <v-btn small @click="setDate('week')">일주일</v-btn>
                  </v-col>
                  <v-col>
                    <v-btn small @click="setDate('month')">1개월</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="filters.keyword"
                  label="검색어"
                  append-icon="mdi-magnify"
                  clearable
                  @keyup.enter="search"
                  dense
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-btn color="primary" @click="search">검색</v-btn>
              </v-col>
              <v-col cols="12" sm="3">
                <v-btn @click="clearParams">초기화</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- 요약 정보 -->
        <v-card class="mb-4">
          <v-card-text>
            <div class="text-h6">총 운영수수료</div>
            <div class="text-h4 primary--text">
              {{ formatCurrency(summaryData && summaryData.totalServiceFee) }}원
            </div>
          </v-card-text>
        </v-card>

        <!-- 데이터 테이블 -->
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="options"
          :server-items-length="totalItems"
          :footer-props="{
            'items-per-page-options': [15, 25, 50, 200, 1000]
          }"
          @click:row="handleRowClick"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.date }}</td>
              <td>{{ item.user.store.name }}</td>
              <td>{{ getLoanStatusText(item.loan && item.loan.status) }}</td>
              <td class="text-right">{{ formatCurrency(item.salesTotal.salesAmount) }}</td>
              <td class="text-right">{{ formatCurrency(item.salesTotal.expectedFee) }}</td>
              <td class="text-right">{{ formatCurrency(item.serviceFee) }}</td>
              <td class="text-right">{{ formatCurrency(item.adjust) }}</td>
              <td class="text-right">{{ formatCurrency(item.refund) }}</td>
              <td class="text-right">{{ formatCurrency(item.nowPayment) }}</td>
              <td class="text-right">{{ formatCurrency(item.loan && item.loan.amount) }}</td>
              <td class="text-right">{{ formatCurrency(item.loan && item.loan.balance) }}</td>
            </tr>
          </template>
          <template v-slot:footer>
            <tr class="font-weight-bold">
              <td colspan="3">합계</td>
              <td class="text-right">{{ formatCurrency(totalRow.salesTotal.salesAmount) }}</td>
              <td class="text-right">{{ formatCurrency(totalRow.salesTotal.expectedFee) }}</td>
              <td class="text-right">{{ formatCurrency(totalRow.serviceFee) }}</td>
              <td class="text-right">{{ formatCurrency(totalRow.adjust) }}</td>
              <td class="text-right">{{ formatCurrency(totalRow.refund) }}</td>
              <td class="text-right">{{ formatCurrency(totalRow.nowPayment) }}</td>
              <td class="text-right">{{ formatCurrency(totalRow.loan && totalRow.loan.amount) }}</td>
              <td class="text-right">{{ formatCurrency(totalRow.loan && totalRow.loan.balance) }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'PaymentRecords',

  data() {
    return {
      loading: false,
      items: [],
      totalItems: 0,
      startMenu: false,
      endMenu: false,
      options: {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['date'],
        sortDesc: [true]
      },
      headers: [
        { text: '정산일', value: 'date' },
        { text: '가맹점', value: 'user.store.name' },
        { text: '상태', value: 'loan.status' },
        { text: '정산대상 매출합', value: 'salesTotal.salesAmount', align: 'right' },
        { text: '카드배달 수수료', value: 'salesTotal.expectedFee', align: 'right' },
        { text: '운영수수료', value: 'serviceFee', align: 'right' },
        { text: '미정산금', value: 'adjust', align: 'right' },
        { text: '과입금', value: 'refund', align: 'right' },
        { text: '고객계좌 입금액', value: 'nowPayment', align: 'right' },
        { text: '대출 원금', value: 'loan.amount', align: 'right' },
        { text: '대출 잔액', value: 'loan.balance', align: 'right' }
      ],
      filters: {
        keyword: '',
        loanStatus: '',
        startDate: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      },
      summaryData: null,
      totalRow: {
        salesTotal: { salesAmount: 0, expectedFee: 0 },
        serviceFee: 0,
        adjust: 0,
        refund: 0,
        nowPayment: 0,
        loan: { amount: 0, balance: 0 }
      },
      loanStatuses: [
        { text: '전체', value: '' },
        { text: '대기', value: 'pending' },
        { text: '진행중', value: 'executing' },
        { text: '완료', value: 'completed' }
      ]
    }
  },

  computed: {
    ...mapGetters('myfinbiz/auth', ['getToken', 'isAuthenticated', 'getUser'])
  },

  watch: {
    options: {
      handler() {
        this.loadData()
      },
      deep: true
    }
  },

  created() {
    if (!this.isAuthenticated) {
      this.$router.push({ name: 'myfinbiz/login' })
      return
    }
    this.loadData()
  },

  methods: {
    formatCurrency(value) {
      if (value === undefined || value === null) return '-'
      return new Intl.NumberFormat('ko-KR').format(value)
    },

    async loadData() {
      if (this.loading) return
      this.loading = true

      try {
        await Promise.all([
          this.loadPaymentRecords(),
          this.loadSummaryData()
        ])
      } catch (error) {
        console.error('Failed to load data:', error)
        this.$store.dispatch('snackbar/showSnackbar', {
          text: '데이터를 불러오는데 실패했습니다.',
          color: 'error'
        })
      } finally {
        this.loading = false
      }
    },

    async loadPaymentRecords() {
      try {
        const params = {
          page: this.options.page,
          limit: this.options.itemsPerPage,
          loanStatuses: this.filters.loanStatus ? [this.filters.loanStatus] : [''],
          startDate: dayjs(this.filters.startDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          endDate: dayjs(this.filters.endDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          keyword: this.filters.keyword || '',
          organizationId: this.getUser.organizationId
        }

        const response = await this.$myfinbizApi.earlyPayment.getPaymentRecords(params)
        const responseData = response.data.data.data

        if (responseData && Array.isArray(responseData.data)) {
          this.items = responseData.data.map(item => {
            const serviceFee = (item.connectTransactions || [])
              .filter(transaction => transaction.type === 'service_fee')
              .reduce((acc, curr) => acc + (curr.amount || 0), 0)

            const others = (item.connectTransactions || [])
              .reduce((acc, curr) => acc + (curr.amount || 0), 0) + (item.deductibleAmount || 0)

            const nowPayment = ((item && item.amount) || 0) - (item.fee || 0) - others + (item.refund || 0)

            return {
              ...item,
              serviceFee,
              nowPayment
            }
          })
          this.totalItems = responseData.total
          this.updateTotalRow()
        }
      } catch (error) {
        console.error('Failed to load payment records:', error)
        throw error
      }
    },

    async loadSummaryData() {
      try {
        const params = {
          page: this.options.page,
          limit: this.options.itemsPerPage,
          loanStatuses: this.filters.loanStatus ? [this.filters.loanStatus] : [''],
          startDate: dayjs(this.filters.startDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          endDate: dayjs(this.filters.endDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          keyword: this.filters.keyword || '',
          organizationId: this.getUser.organizationId
        }

        const response = await this.$myfinbizApi.earlyPayment.getPaymentSummary(params)
        console.log('response', response)
        if (response.data && response.data.data) {
          this.summaryData = response.data.data.data
        }
      } catch (error) {
        console.error('Failed to load summary data:', error)
        throw error
      }
    },

    updateTotalRow() {
      this.totalRow = {
        salesTotal: {
          salesAmount: this.items.reduce((sum, item) => sum + (item.salesTotal.salesAmount || 0), 0),
          expectedFee: this.items.reduce((sum, item) => sum + (item.salesTotal.expectedFee || 0), 0)
        },
        serviceFee: this.items.reduce((sum, item) => sum + (item.serviceFee || 0), 0),
        adjust: this.items.reduce((sum, item) => sum + (item.adjust || 0), 0),
        refund: this.items.reduce((sum, item) => sum + (item.refund || 0), 0),
        nowPayment: this.items.reduce((sum, item) => sum + (item.nowPayment || 0), 0),
        loan: {
          amount: this.items.reduce((sum, item) => sum + ((item.loan && item.loan.amount) || 0), 0),
          balance: this.items.reduce((sum, item) => sum + ((item.loan && item.loan.balance) || 0), 0)
        }
      }
    },

    setDate(type) {
      this.filters.endDate = dayjs().format('YYYY-MM-DD')
      if (type === 'today') {
        this.filters.startDate = this.filters.endDate
      } else {
        this.filters.startDate = dayjs().subtract(1, type).format('YYYY-MM-DD')
      }
      this.search()
    },

    search() {
      this.options.page = 1
      this.loadData()
    },

    clearParams() {
      this.filters = {
        keyword: '',
        loanStatus: '',
        startDate: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      }
      this.search()
    },

    handleRowClick(item) {
      this.$router.push({
        name: 'myfinbiz/customer/early-payments',
        params: {
          customerId: item.user.customer.id,
          date: item.date
        }
      })
    },

    getLoanStatusText(status) {
      const statusMap = {
        pending: '대기',
        executing: '진행중',
        completed: '완료'
      }
      return statusMap[status] || status
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table {
  ::v-deep {
    td, th {
      white-space: nowrap;
      padding: 0 8px !important;
    }

    .text-right {
      text-align: right !important;
    }

    tr.v-data-table__selected {
      background: #f5f5f5 !important;
    }
  }
}
</style>
